<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <ZaloOA v-if="isActiveTab('tab-oa')" />
      <ZaloTemplate v-if="isActiveTab('tab-template')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :color="isActiveTab('tab-oa') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-oa')"
      >
        OA
      </v-btn>
      <v-btn
        :color="isActiveTab('tab-template') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-template')"
      >
        Template
      </v-btn>
      <!-- <v-btn
        :color="isActiveTab('tab-script') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-script')"
      >
        Kịch bản
      </v-btn> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    ZaloOA: () => import("@/components/zns/ZaloOA.vue"),
    ZaloTemplate: () => import("@/components/zns/ZaloTemplate.vue"),
  },
  data: () => ({
    isLoading: false,
    tab: "tab-oa",
    items: [],
    isRiRo: false,
  }),
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
  mounted() {
    const riro = this.getUrlParameter("riro");
    this.isRiRo = !!riro;
    if (this.isRiRo) {
      this.tab = "tab-riro";
    }
  },
};
</script>

<style scoped></style>
